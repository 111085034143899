.resize-desktop-logo-video {
  max-width: 140%;
  max-height: 140%;
}

.btn.side-panel-action-btn {
  padding: 5px 5px 5px 5px;
}

.tr.hover {
  cursor: pointer;
  /* whatever other hover styles you want */
}

.billWidgets {
  height: 126px;
}

.patient-buttons {
  cursor: pointer !important;
}

.table-buttons {
  height: 32px !important;
  line-height: 1 !important;
}

.livesearchResults {
  position: absolute;
  top: 100%;
  background-color: white;
  border: 0px solid black;
  z-index: 50;
  display: none;
  min-height: 45px;
  overflow: auto;
  width: 100%;
}

.praclivesearchResults {
  top: 100%;
  background-color: white;
  border: 0px solid black;
  display: none;
  min-height: 45px;
  overflow: auto;
  width: 100%;
}

.editstatementNoteBox {
  display: block;
  width: 80%;
  max-width: 95%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
  border: 1px solid black;
}

.modalNavBar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /*background-color: #f3f3f3;*/
}

.statementOptions {
  float: left;
}

.statementParamDetails {
  /* float: right;*/
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.optionsText {
  display: block;
  color: #188ae2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.optionsText:hover {
  background-color: #e8e8e8;
}

.incDateNum {
  width: 100px;
  height: 21px;
  margin-left: 10px;
  font-weight: bold;
}

.uc-first {
  text-transform: capitalize !important;
}

.stepwizard-step p {
  margin-top: 0px;
  color: #666;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}
.stepwizard-step button[disabled] {
  /*opacity: 1 !important;
    filter: alpha(opacity=100) !important;*/
}
.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  color: #ffecec;
  background-color: #bbb;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.setupWizard-panel {
  border: solid;
  border-color: #188ae2;
  border-radius: 7px;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

#myProgress {
  width: 100%;
  background-color: #c3c3c3;
}

#myBar {
  width: 1%;
  height: 30px;
  background-color: #188ae2;
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: white;
}

.noStatement {
  width: 105px;
  height: 35px;
}

.patPlan {
  width: 105px;
  height: 35px;
}

#mailRemainder {
  color: red;
  font-size: 25px;
}

.paramRadio {
  height: 18px;
  width: 18px;
}

.pay-div {
  height: 70px;
  margin: auto;
  border: 2px solid gray;
  padding: 10px;
}

/* Hide HTML5 Up and Down arrows. KEEP THIS*/
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.adjustment {
  display: inline-block;
  width: 40%;
}
.adjustment-code {
  display: inline-block;
  text-transform: Uppercase;
}

.deductible {
  display: inline-block;
}
.coinsurance {
  display: inline-block;
}
.copayment {
  display: inline-block;
}

/*
@media screen and (min-width: 1024px) {
.charge-stat{
    width: 12.499999995% !important;
    }
}
@media screen and (max-width: 767px) {
	.charge-stat{
        width: 33.3333333332% !important;
	}
}
*/

.seeMore {
  font-family: arial, sans-serif !important;
}

@media screen and (min-width: 768px) {
  .pay_icons {
    text-align: right !important;
  }
}

/****************************BLUR***********************/
.blur-focus {
  filter: blur(1.5rem);
  pointer-events: none;
}

/*BLUR BUTTON FOR HIDING FEATURES AND UPGRADING PLAN*/
.blur-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  /*background-color: #555;*/
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  z-index: 3000 !important;
  filter: none;
}

/*TELARUS LOGIN*/
.telarus-demo-register {
  color: #777777;
  float: left;
}

.telarus-demo-login {
  color: #777777;
  float: left;
}

/*******************************************************/

.eligdivs {
  /*background-color: #188ae2 !important;*/
}
.eligcheck {
  cursor: pointer;
  display: block;
}

.elig-panel {
  cursor: pointer;
}

.view-elig-report {
  cursor: pointer;
}

.pagebreak {
  page-break-after: always;
}

/* FIX MODAL FOCUS!!*/
.modal {
  overflow: auto !important;
}

.modal.modal-disable-overflow {
  overflow: visible !important;
}

/*Claim Control Div*/
.claim-control {
  float: none !important;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.claim-charge {
  background-position: left;
  background-color: #188ae2;
}

.cpt-center {
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.dxtest {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  color: #5b69bc;
}

.dx-item {
  cursor: pointer;
}

.dx-dragger {
  background-color: #188ae2;
  border-radius: 5px;
  padding: 2px 5px !important;
  color: #ffffff;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.dx-widget {
  border-radius: 5px;
  background-color: #5b69bc;
}

.ui-state-highlight {
  background-color: #f9c851;
}

SVG {
  display: block;
  max-height: 100% !important;
}

.cl-table {
  cursor: pointer;
}

#claim_charge_table {
  font-family: Roboto, sans-serif !important;
}

.charge-edit {
  cursor: pointer;
}

.charge-history {
  cursor: pointer;
}

.dx-sb-quick-input {
  width: 70% !important;
  display: inline-block;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
}

.dx-input {
  width: 70px !important;
  height: 40px !important;
  display: inline-block !important;
  margin-right: 2px;
}

.modifier-select1 {
  display: inline-block;
  width: 70px;
  height: 30px !important;
  margin-top: 5px;
}

#modal_cpt {
  width: 120px;
  height: 30px;
  display: inline-block;
}
#modal_units {
  width: 50px;
  height: 30px;
  display: inline-block;
}
#modal_charge {
  width: 70px;
  height: 30px;
  display: inline-block;
}

.large-modal {
  width: 80% !important;
}

.medium-modal {
  width: 50% !important;
}

@media screen and (max-width: 767px) {
  #modal_charge {
    display: inline-block;
  }
  #modal_units {
    display: inline-block;
    margin-top: 5px;
  }

  label[for='modal_units'] {
    margin-left: 0px;
  }

  .large-modal {
    width: 95% !important;
  }
}

@media screen and (min-width: 1024px) {
  .mobile_break {
    display: none;
  }
}

.charge-date-range {
  display: inline-block !important;
  width: 200px;
}

.print-sb-cpt {
  font-size: 16px;
  margin-top: 30%;
  padding: 7px !important;
  border-radius: 7px;
  font-family: arial, sans-serif;
  box-shadow: 2px 2px #ccc;
}

.print-sb-cpt2 {
  font-size: 16px;

  padding: 7px !important;
  border-radius: 7px;
  font-family: arial, sans-serif;
  box-shadow: 2px 2px #ccc;

  margin: 0px 0px 6px 0px !important;
}

.cpt-modal-dialog {
  overflow-y: initial !important;
}
.cpt-modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow: scroll !important;
}

.dx-modal-dialog {
  overflow-y: initial !important;
}
.dx-modal-body {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  overflow: scroll !important;
}

.cpt-inputs {
  position: relative;
  width: 99.5% !important;

  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1024px) {
  .cpt-inputs-fixed {
    z-index: 3000 !important;
    position: fixed !important;
    top: 1px !important;
    left: 1px !important;
    width: 99.8% !important;
  }
}
.cpt-input {
  height: 40px !important;
  display: inline-block !important;
  width: 75%;
  margin-right: 2px;
}

.cpt-modal-instruction {
  margin-top: 10px !important;
  margin-left: 25px !important;
  margin-right: 12px !important;
}

.live-edit-buttons {
  z-index: 3000;
  /*
    position: fixed;
    bottom: 0px;
    left: 3px;*/
  width: 99.5%;

  min-height: 20px;
  /*height: 30px;*/
  padding: 10px;
  margin-bottom: 20px;
  background-color: #1c94bc;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.live-edit-sb {
  /*
    position: relative !important;
    bottom: 15px !important;
    padding: 5px 12px !important;
    display: inline-block;
    */
}

.expand-all {
  /*
    position: relative !important;
    bottom: 15px !important;
    padding: 5px 12px !important;
    display: inline-block;
    */
}

.cpt-superbill {
  position: relative;
  margin-bottom: 50px;
}

@media screen and (max-width: 320px) {
  .live-edit-buttons {
    z-index: 3000;
    /*
            position: fixed;
            bottom: 0px;
            left: 3px;*/
    width: 99.5%;

    min-height: 20px;
    height: 75px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #188ae2;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .live-edit-cpt {
    position: relative !important;
    bottom: 15px !important;
    padding: 5px 12px !important;
    display: inline-block;
    width: 100%;
  }

  .expand-all {
    position: relative !important;
    bottom: 15px !important;
    padding: 5px 12px !important;
    display: inline-block;
    width: 100%;
  }
}

.sort-bg {
  margin: 10px;
  background: #f9c851;
  color: #fff;
  font-size: 21px;
  border: 2px dashed #fff;
  border-radius: 10px;
  box-shadow:
    0 0 0 4px #ccc,
    2px 1px 6px 4px rgba(10, 10, 0, 0.5);
  text-shadow: -1px -1px #aa3030;
  font-weight: normal;
}

select:focus {
  /*background: lightblue;*/
  border: 1px solid lightblue !important;
  box-shadow: 0 0 3px lightblue !important;
  -moz-box-shadow: 0 0 3px lightblue !important;
  -webkit-box-shadow: 0 0 3px lightblue !important;
}

input:focus {
  border: 1px solid lightblue !important;
  box-shadow: 0 0 3px lightblue !important;
  -moz-box-shadow: 0 0 3px lightblue !important;
  -webkit-box-shadow: 0 0 3px lightblue !important;
}

.superbill-temp-select {
  display: inline-block;
  height: 35px !important;
  width: 60%;
  border-radius: 5px;
  margin-right: 5px;
  text-transform: uppercase;
}

.superbill-temp-select option {
  font-weight: 600;
  height: 50px;
}

@media screen and (max-width: 812px) {
  .superbill-temp-select {
    display: inline-block;
    width: 100%;
  }

  .charge-date-range {
    display: inline-block !important;
    width: 100%;
    height: 25px !important;
  }

  .sb-cart-dx {
    text-align: center;
  }

  .sb-cart-trash {
    margin-top: 24px;
  }
}

@media screen and (max-width: 736px) {
  .sb-cart-trash {
    margin-top: 2px;
  }
  .modifier-select {
    width: 70px;
  }
}

@media screen and (max-width: 414px) {
  .sb-cart-trash {
    margin-top: 24px;
  }
  .modifier-select {
    width: 70px;
  }
}

.list-inline {
  display: inline-block;
}
.sb-cart-item {
  /* padding: 1px 5px 3px 5px;*/
}

.sb-cart-units1 {
  width: 55px !important;
  height: 30px !important;
  display: inline-block;
  color: #3c763d !important;
  font-size: 14px;

  min-height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.sb-cart-charge1 {
  width: 60px !important;
  height: 30px !important;
  display: inline-block;
  color: #3c763d !important;
  font-size: 14px;

  min-height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.sb-cart-advanced-btn {
  cursor: pointer;
}

.sb-cpt-fee {
  width: 70px !important;
  font-weight: 500 !important;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

.liveEditMenu {
  width: 100% !important;
  text-transform: uppercase;
  font-size: 14px;
}

.cpt-pick {
  cursor: pointer;
  font-family: Arial, sans-serif;
}

#location_code {
  height: 27px;
  font-size: 11px;
  margin-top: 5px !important;
  font-family: arial, sans-serif;
}

.show-insurance {
  margin-right: 14px;
  cursor: pointer;
}

.test-notify-badge {
  position: absolute;
  right: 45%;
  top: -15px;
  background: white;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: darkblue;
  padding: 0px 10px;
  font-size: 20px;
}

.notify-badge {
  background: red;
  position: relative;
  top: -13px;
  left: -28px;
  border-radius: 50%;
  font-size: 8px;
}

.claim-detail-btn-badge {
  top: -1px !important;
}

.auth-count-btn-badge {
  top: -1px !important;
}

.insurance-panel-indicator {
  float: right;
  font-family: arial, sans-serif;
  font-size: 12pt;
}

.auth-form {
  font-weight: 600 !important;
  font-size: 14px;
}

.auth-form input {
  text-transform: uppercase;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.auth-form textarea {
  text-transform: uppercase;
  font-size: 16px;
  font-family: Arial, sans-serif;
}

.auth-form select {
  text-transform: uppercase;
  font-size: 18px;
  background: lightblue;
  font-weight: 600 !important;
}

.advanced-insurance-toggle {
  cursor: pointer;
  text-align: center;
}

.remove-auth {
  cursor: pointer;
}

.remove-supervising {
  cursor: pointer;
}

.remove-referring {
  cursor: pointer;
}

@media screen and (max-width: 414px) {
  .sb-cpt-description {
    width: 100%;
    margin-top: 5px;
    margin-left: 0px !important;
  }
  .sb-cpt-fee {
    width: 100%;
    margin-top: 5px;
    margin-left: 0px !important;
    font-weight: 500 !important;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    font-family: arial, sans-serif;
  }

  .cpt-tag-input {
    display: none !important;
  }
  .dx-tag-input {
    display: none !important;
  }

  .sb-print-code-label {
    margin-bottom: 10px !important;
  }
  .sb-item-description {
    margin-left: 0px !important;
    font-weight: 500 !important;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    font-family: arial, sans-serif;
  }
}

.cat-title-input {
  font-weight: 500 !important;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  font-family: arial, sans-serif;
}

.cpt-tag-input {
  text-transform: uppercase;
  width: 95% !important;
  display: inline-block;
}
.dx-tag-input {
  text-transform: uppercase;
}

.sb-cpt-description {
  width: 100%;
  font-family: arial, sans-serif;
}

.tm-tag {
  padding: 0.2em 0.6em 0.3em !important;
  font-size: 16px !important;
  font-weight: bold !important;
  line-height: 1 !important;
  color: #fff !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.25em !important;
  background-color: #10c469 !important;
  margin-bottom: 20px !important;
}

.cpt-label {
  position: relative;
  top: 6px;
  font-size: 14px !important;
}

.add-new-patient {
  cursor: pointer;
}

.sb-item-description {
  width: 100%;
  font-weight: 500 !important;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
}

#modal_provider_datatable tr {
  cursor: pointer;
}
#modal_location_datatable tr {
  cursor: pointer;
}
#modal_referring_datatable tr {
  cursor: pointer;
}
#modal_patient_datatable tr {
  cursor: pointer;
}
#modal_insurance_datatable tr {
  cursor: pointer;
}

.modifier-select {
  font-family: arial, sans-serif;
}
.sb-cart-delete {
  cursor: pointer;
}

.curved-edge {
  border-radius: 10px !important;
}
.add-shadow {
  box-shadow: 3px 3px #ccc !important;
}

.mark-reviewed {
  cursor: pointer;
}

.auto-post {
  cursor: pointer;
}

.more-filters-btn {
  cursor: pointer;
}
#allChargesTable th {
  white-space: nowrap;
}
#allChargesTable td {
  white-space: nowrap;
}
.orangeTable {
  background-color: #ffac90;
}

.payer_aka {
  font-style: italic;
  font-family: arial, sans-serif;
}

.delete_aka {
  cursor: pointer;
}
table {
  font-family: arial, sans-serif;
}

/*TEST OF BOOTSTRAP PRINT*/

@media print {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .visible-xs {
    display: none !important;
  }
  .hidden-xs {
    display: block !important;
  }
  table.hidden-xs {
    display: table;
  }
  tr.hidden-xs {
    display: table-row !important;
  }
  th.hidden-xs,
  td.hidden-xs {
    display: table-cell !important;
  }
  .hidden-xs.hidden-print {
    display: none !important;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
  #claim_clearinghouse_table .open-era-dropdown {
    display: none !important;
    min-width: 0px !important;
    width: 0px !important;
  }
}

.superbill-widget-container {
  display: flex;
  flex-direction: row;

  .col-md-3 {
    flex: 1;
    display: flex;

    .widget {
      display: flex;
      flex: 1;

      .widget-body {
        flex: 1;
      }
    }
  }

  .widget-body {
    min-height: 146px !important;
    overflow: auto !important;
  }
}

/*END BOOTSTRAP PRINT TEST */

#detailBillTable {
  width: 100%;
  font-size: 11pt;
  max-width: 100%;
  white-space: nowrap;
}

#detailBillTable .sortable-handle {
  display: inline-block;
  cursor: pointer;
}

#detailBillTable th {
  background-color: #dff0d8;
}

#detailBillTable input {
  display: inline;
  width: 55px;
  height: 25px;
  text-transform: uppercase;
}

#detailBillTable select {
  display: inline;
  height: 25px;
}

.billDetailFromDate {
  width: 135px !important;
  text-transform: none !important;
}

.billDetailThruDate {
  width: 135px !important;
  text-transform: none !important;
}

.billDetailCPT {
  font-weight: 600;
}

.billDetailNote {
  width: 130px !important;
}

.billDetailNDC {
  width: 100px;
}

.billDetailPrint {
  text-transform: uppercase;
}

.ticket-note-container {
  white-space: normal;
  width: 250px;
}

/*Get rid of step buttons on date input*/
input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

.sticky {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  top: 0;
  left: -200px !important;
}

.sticky2 {
  position: fixed;
  left: 1600px;
  top: 50;
  width: 200px;
  color: white;
  text-align: center;
  z-index: 4000;
  border-radius: 10px;
}

.sticky3 {
  position: fixed;
  left: 1600px;
  top: 500px;
  width: 200px;
  color: white;
  text-align: center;
  z-index: 4000;
}

.superbillWrapper {
  border-style: dashed;
}

.claimCPT {
  color: #10c469;
  font-weight: 600;
}

.productList {
  cursor: pointer;
}

.lmd_productPlans {
  display: none;
}

.worklist-alert {
  padding: 10px !important;
}

.worklist-alert-btn {
  float: right !important;
}

.worklist-alert span {
  line-height: 34px !important;
}

.worklist-alert > div:after {
  clear: both !important;
  content: '';
  display: table !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

.worklist-row {
  &.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}

.typeahead,
.tt-query,
.tt-hint {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999;
}

.tt-menu {
  width: 422px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 14px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.modifier-datasets {
  display: inline-block;
  width: 50%;
  float: right;
  font-family: arial, sans-serif;
  text-align: left;
}

.icn-item {
  cursor: pointer;
}

.insights-alert-filters {
  overflow: auto;
}

.insights-report-widget-menu {
  color: #31708f;
  cursor: pointer;
}

.insights-report-widget-menu:hover {
  /*color: #FF5722;*/
  transform: scale(1.1);
}

.insights-filters-toggle {
  cursor: pointer;
}

.btn-insight-menu {
  background-color: #31708f;
  width: 100%;
  color: white;
}

.btn-insight-menu:active {
  color: #f9c851;
}

.btn-insight-selected {
  color: #f9c851 !important;
}

.btn-date-filter-set {
  background-color: #31708f;
  color: white;
}

.snapshot-stat {
  display: none;
}

.visualization-spinner-icon {
  margin-left: 45%;
  margin-top: 25%;
}

.narr-data {
  font-weight: 600;
}

.insightFilterDisplay {
  font-weight: 600;
  font-family: arial, sans-serif;
  border-left: 2px solid;
  text-align: center !important;
  text-transform: uppercase;
}

.ar-snapshot-small {
  font-family: arial, sans-serif !important;
  font-size: 14px !important;
}

.patient-statement-link {
  cursor: pointer;
}
.patient-statement-link:hover {
  color: #fff;
  background-color: #1576c2;
}

.badge-pdf {
  background-color: #fff;
  color: red;
}

#print_patient_balance {
  font-family: arial, sans-serif;
}

.app-content {
  font-family: arial, sans-serif !important;
}

.modal-content {
  font-family: arial, sans-serif !important;
}

.patient_accountid {
  font-size: 14px;
}

.update-patient-address {
  cursor: pointer;
}

.active-page {
  color: #1576c2;
}

.document-buttons {
  width: 100%;
}

.doc-association {
  padding: 5px 5px 5px 10px !important;
  border: 0px;
}

.doc-assoc-labels {
  cursor: pointer;
  text-transform: uppercase;
}

.doc-assoc-delete {
  cursor: pointer;
}

.claimExtraButtons {
  display: none;
}

#table_tags .checkbox {
  margin-bottom: 5px;
}

.statement-note {
  cursor: pointer;
}

.statement-note:hover {
  transform: scale(1.5);
}

#claims-table-report.dataTable tbody td {
  word-break: break-word;
  vertical-align: top;
}

.buttons-columnVisibility {
  text-transform: uppercase;
}

.buttons-columnVisibility.active {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important ;
  background-image: none !important;
}

.reportButtons {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff !important;
  background-color: #337ab7 !important;
  border-color: #2e6da4 !important ;
  background-image: none !important;
}

.reportSuccessButtons {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff !important;
  background-color: #10c469 !important;
  border-color: #10c469 !important ;
  background-image: none !important;
}

.ds-buttons.reportButtons {
  width: 500px;
}

.modal-full {
  min-width: 90%;
}

.modal-full .modal-content {
  min-height: 80vh;
}

.report-filters-btn {
  display: inline-block;
}

.report-list {
  border: 1px solid #eeeeee !important;
}

.report-options-open {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  margin: 5px 1px 3px 0px;
  border: 1px solid rgba(81, 203, 238, 1);
}

.savedNdcTable {
  font-family: arial, sans-serif;
}

.claim-narrative-btn-badge {
  top: -1px !important;
}

.widget-title.dashboard-message-title {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.lmd-circle-icon {
  background: #fcf8e3;
  padding: 16px;
  border-radius: 50%;
}

.dashboard-title {
  padding: 10px 16px !important;
}

.dash-stat-widget {
  /*
    border-radius: 15px;
    */
}

.dash-stat-widget-header {
  /*
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    */
  background: #dff0d8;
}

.dash-selected {
  font-weight: 600;
}

.dash-ar {
  cursor: pointer;
}

.snapshot-spinner {
  display: inline;
}

.fc-day-number {
  cursor: pointer;
  font-size: 14px !important;
  color: gray !important;
  padding-top: 6px !important;
  padding-right: 10px !important;
}
.fc-day-number:hover {
  transform: scale(1.2);
}

.lmd-circle-mini-icon {
  background: #fcf8e3;
  padding: 8px;
  border-radius: 50%;
}

.simple-page-video-patient {
  padding-top: 4% !important;
}

.fc-event-container .fc-event {
  margin-bottom: 1px !important;
}

.newApptFname {
  text-transform: uppercase;
}
.newApptLname {
  text-transform: uppercase;
}
.editApptFname {
  text-transform: uppercase;
}
.editApptLname {
  text-transform: uppercase;
}

.lmd-glowing-border {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 20px #9ecaed;
  border: 2px solid #dadada;
  border-radius: 7px;
  margin-top: -10px;
}

.lmd-input-success {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.lmd-btn-small {
  padding: 5px 10px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

/*CSS FOR FULL CALENDAR RIGHT MEANU BUTTONS*/
.fc-right button {
  padding: 6px !important;
}

.fc-today {
  background: #fcf8e3;
}

.fc-popover {
  background-color: white;
}

.fc-event-container {
  cursor: pointer;
}

.fc-timegrid-col-frame {
  border: dotted 0.25px #bbb;
}

/*NEW PAYMENT ELEMENTS - EXPRESS ENTRY*/

/*

.express-entry-wrap{
    border: 3px solid #188ae2;
    /* background-color: #d9edf7;
    border-radius:5px;
}


.express-entry-header{
    background-color: #d9edf7;
    display: flex;
    border-bottom: 1px solid white;
    height: 36px;
    line-height: 36px;
}

.express-entry-footer{
    background-color: #d9edf7;
    display: flex;
    border-bottom: 1px solid white;
    height: 36px;
    line-height: 36px;
}

.express-entry-li{
    background-color: #ffffff;
    display: flex;
    border-bottom: 1px solid  #f5f6f8;
    border-left: 1px solid white;
    height: 40px;
    line-height: 36px;
}

.express-entry-li:focus-within {
  background-color: #f5f6f8;
}

.express-entry-li-top{
    background-color: #ffffff;
    display: flex;
    border-bottom: 1px solid #f5f6f8;
    border-left: 1px solid white;
    height: 40px;
    line-height: 36px;
}

.ee-focus{
    background-color: #dff0d8;
    box-shadow: inset 0 0 0 1px #74a730;
    transform: scale(1.0);
}

.ee-cell{
    border-right: 1px solid #f5f6f8; /*1px solid white;
    font-weight:600;
    font-family:verdana;
    font-size:11px;
}

.ee-cell1:hover{
    background-color: #e5f4ff;
    box-shadow: inset 0 0 0 1px #99d4ff
}

ee.cell1:focus{
    background-color: #e5f4ff;
    box-shadow: inset 0 0 0 1px #99d4ff
}

.ee-sendto{
    width:226px;
}

.ee-left-indicator{
    width: 10px;
}

.ee-right-indicator{
    width: 10px;
}
.ee-date{
    width:85px;
}

.ee-code{
    width:80px;
}

.ee-quickbuttons{
    width:108px;
}

.ee-quickpay{
    width:36px;
    cursor:pointer;
}
.ee-quickpay:hover{
    transform:scale(1.1);
}


.ee-quickdeny{
    width:36px;
    cursor:pointer;
}
.ee-quickdeny:hover{
    transform:scale(1.1);
}

.ee-quicksettle{
    width:36px;
    cursor:pointer;
}
.ee-quicksettle:hover{
    transform:scale(1.1);
}

.ee-paid-input{
    font-size: 10px;
}

.ee-allowed-input{
    font-size: 10px;
}

.ee-allowed{
    width:100px;
}

.ee-allowed-input-group{
    margin-top: 5px;
}

.ee-paid{
    width:100px;
}

.ee-paid-input-group{
    margin-top: 5px;
}

.ee-pr-adj{
    margin-top: 2px;
    font-size: 11px;
    text-transform: uppercase;
}

.ee-crossover{
    width:35px;
    cursor:pointer;
    font-size:8px;
    line-height: 10px;
    margin-top: 8px;
}

.ee-more{
    width:35px;
    cursor:pointer;
    font-size:8px;
    line-height: 10px;
    margin-top: 8px;
}

.ee-crossover-checkbox{
    height:16px;
    width:16px;
    display:inline-block;
    
}
.ee-checkbox{
    display:inline-block;
    
}

.ee-dispo-group{
    margin-top: 5px;
    
}

.ee-dispo{
     font-size: 11px;
}

.ee-input-addon{
    padding: 6px 4px;
}

.express-entry-li-detail{
    border: 2px solid #74a730;
    border-radius: 10px;
}

.ee-claim-label{
    display: inline;
    padding: .3em .6em .3em;
    font-size: 80%;
    font-weight: bold;
    line-height: 2;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    /* vertical-align: middle; 
    border-radius: .25em;
    cursor:pointer;
}

.ee-patient-link{
    background-color:#ff8acc;
}

.ee-btn{
    padding: 4px 8px;
    margin-top: 2px;
}


*/

.ff-arial {
  font-family: arial, sans-serif !important;
}

.tour-bottom-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 60px;
  background: #5b69bc;
  transition:
    height 0.2s,
    bottom 0.2s;
  flex: 0 0 auto;
  min-width: 0;
  min-height: 0;
  padding-top: 10px;
}

.stripe-activate-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 1px 16px !important;
  font-size: 80%;
}

.stripe-inactive-widget {
  border-radius: 5px;
  border: 1px solid #ddd;
}

.stripe-active-widget {
  border-radius: 5px;
  border: 1px solid #ddd;
}

.ar-practice-info-table > tbody > tr:hover {
  background-color: #10c469;
  color: #fff;
  font-weight: 600;
  border: 2px solid #6a6c6f;
}

.ar-payer-info-table > tbody > tr:hover {
  background-color: #10c469;
  color: #fff;
  font-weight: 600;
  border: 2px solid #6a6c6f;
}

.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}

.width-200 {
  width: 200px;
}

.claims-enrollment {
  cursor: pointer;
}

.elig-enrollment {
  cursor: pointer;
}

.era-enrollment {
  cursor: pointer;
}

.visualization-datatable {
  width: 100%;
  pointer-events: none;
}

#thisBillsAdmitDate > .label {
  padding: 0.2em 0.5em 0.3em;
}

.copy-claim {
  cursor: pointer;
}

.appt-form-control {
  border-radius: 3px;
  height: 24px;
  box-shadow: none;
  border-color: #ddd;
}

.has-error {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.apptEditField {
  font-weight: 600;
}

.appt_time {
  font-family: arial, sans-serif;
}

#patientTagsList {
  overflow-wrap: break-word;
}

.calendarAccordian {
  padding: 12px;
}

.calendarAlert {
  padding: 8px;
}

#print_resp_address1,
#print_resp_address2,
#print_resp_city,
#print_resp_state,
#print_resp_zip {
  font-family: arial, sans-serif;
}

#print_address1,
#print_address2,
#print_city,
#print_state,
#print_zip {
  font-family: arial, sans-serif;
}

.apptTimeEditContainer {
  background: #d9edf7;
  border-radius: 10px;
  padding-bottom: 20px !important;
}

.ui-timepicker-wrapper {
  z-index: 2500;
}

.hoursDayLabel {
  width: 40px;
}

.calSetSwitch {
  height: 25px;
  width: 25px;
  margin-top: 16px;
}

.calSetAccordion {
  padding-top: 2px;
  padding-bottom: 12px;
  padding-right: 12px;
  padding-left: 10px;
}

.calDot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.calWaitProv {
  /* margin-left:5px !important; */
}

.calWaitBtn {
  /* height:30px !important; */
  width: 100% !important;
}

.calWaitDot {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: lightgray;
  border-radius: 50%;
  border: #bbb 1px solid;
  background-size: cover;
  background-position: center;
  background-repeat: none;
}

.calWaitDot span {
  font-weight: 700;
  color: #fff;
  font-style: normal;
  font-size: 90%;
}

.calWaitStatus {
  min-width: 130px !important;
}

.calWaitStatus .btn {
  margin-bottom: 0px !important;
}

.calWaitPaid {
  /* margin-left: -8px; */
}

.calEligButton {
  cursor: pointer;
}
.calAuthButton {
  cursor: pointer;
}

.stmtFixHeight {
  min-height: 405px;
}

#mailTypeGroup {
  display: inline-block;
}

.chat {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: rgb(0, 120, 254);
  position: relative;
}

.mine .message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: rgb(0, 120, 254);
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.smsPreviewDevice {
  transform: scale(0.5);
  margin: -210px;
}

.stmtFixHeightBots {
  min-height: 500px;
  max-height: 500px;
}

.emailSampleContainer {
  transform: scale(0.7);
  margin: -100px;
  margin-top: -125px;
  /* background-color: #383434; */
  margin-bottom: -30px;
  padding: 1rem;
}

.integration-left-tabs {
  min-height: 600px !important;
}

.integration-right-tabs {
  min-height: 600px !important;
  overflow: auto !important;
}

.integration_welcome_headline {
  margin-top: 32px;
}

.elation-beta-alert {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.elation-beta-button-header {
  margin-top: 20px !important;
}

.integration-label-h4 {
  padding-top: 3px;
}

.integration-head-hr {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.promo-tab-integration-header {
  padding: 0px !important;
}

.integration-background {
  background-color: #d9edf7;
}

.integration-display-widget {
  border-radius: 15px;
}

.integration-select-css {
  font-weight: 600 !important;
  font-size: 16px !important;
}

#elation_sync_table_length {
  display: inline-block;
  position: relative;
  float: left;
}

#elation_sync_table_paginate {
  display: inline-block;
  position: relative;
  float: right;
}

.elation-login-btn {
  background: url(/assets/images/elation-icon.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #edeff1;
  border: 1px solid #edeff1;
}

.elation-icon {
  border-radius: 5px;
  height: 30px;
  width: auto;
}

.elation-logo-svg-pages {
  width: 250px;
}

.btn-outline.btn-logout {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.icon-logout {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .btn-outline.btn-logout {
    color: #188ae2;
    background-color: transparent;
    border-color: #188ae2;
  }
  .icon-logout {
    color: #188ae2;
  }
}

// needed to allow Select2 components to display dropdowns in modals
body {
  --vds-popover-z-index: 100002;
}

// reset some global element styling for vital
.vital-reset {
  input:focus {
    border: 0px solid lightblue !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

// Clearinghouse Transactions Table
#claim_clearinghouse_table {
  .open-era-dropdown {
    min-width: 100px;
  }
}
